import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Alert, Button, Col, Form, Row } from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from 'yup';
import { useForgotPasswordMutation } from 'api/authSlice';
import Error from '@apex/react-toolkit/components/Error';
import ForgotPasswordSuccess from 'App/AuthRoutes/ForgotPassword/ForgotPasswordSuccess';
import { translate } from '@apex/react-toolkit/lib';
import styles from 'App/AuthRoutes/AuthRoutes.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ForgotPassword = () => {
  const [forgotPassword, { data, isLoading, isSuccess, error }] = useForgotPasswordMutation();
  const [email, setEmail] = useState();

  const schema = yup.object({
    email: yup.string().email().required('Email Address is required'),
  });

  if (data) return (<ForgotPasswordSuccess email={email} />);

  return (
    <>
      {
        isSuccess && (
          <Alert variant="success">
            {translate('passwordResetRequested')}
          </Alert>
        )
      }
      {error && <Error error={error} />}
      <Formik
        validationSchema={schema}
        initialValues={{ email: '' }}
        onSubmit={(formInput) => {
          setEmail(formInput.email);
          forgotPassword(formInput);
        }}
      >
        {({
          handleSubmit,
          handleChange,
          touched,
          values,
          errors,
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Control
                required
                name="email"
                type="email"
                placeholder="Enter email"
                size="lg"
                value={values.email}
                onChange={handleChange}
                disabled={isLoading}
                isValid={touched.email && !errors.email}
              />
            </Form.Group>
            <Button
              variant="danger"
              type="submit"
              size="lg"
              disabled={isLoading}
              className="text-nowrap w-100"
            >
              {isLoading && <FontAwesomeIcon icon="spinner" pulse />} {translate('sendPasswordRecoveryEmail')}
            </Button>
          </Form>
        )}
      </Formik>
      <Row className="my-3">
        <Col className="mx-auto">
          <hr className="border border-gray" />
        </Col>
      </Row>
      <div className="text-center">
        <Link className={styles.authRouteLink} to="/login">{translate('returnToLogin')}</Link>
      </div>
    </>
  );
};

export default ForgotPassword;
