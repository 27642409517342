import { FC } from 'react';
import { Link } from 'react-router-dom';
import { Alert, Col, Row } from 'react-bootstrap';
import { translate } from '@apex/react-toolkit/lib';
import styles from 'App/AuthRoutes/AuthRoutes.module.css';

const NewVerificationSuccess: FC<{
  email: string
}> = ({ email }) => {
  return (
    <>
      <Alert variant="success">
        {translate('ifYouHaveAnAccount', { emailAddress: email })}
      </Alert>
      <Row className="my-3">
        <Col className="mx-auto">
          <hr className="border border-gray" />
        </Col>
      </Row>
      <Link className={styles.authRouteLink} to="/login">{translate('returnToLogin')}</Link>
    </>
  );
};

export default NewVerificationSuccess;
