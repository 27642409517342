import React from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { translate } from '@apex/react-toolkit/lib';
import styles from 'App/AuthRoutes/AuthRoutes.module.css';
import ApexLogo from 'images/apex_logo_sm.png';

const Support: React.FC = () => {
  return (
    <div className={styles.loginBackground}>
      <div
        className="d-flex justify-content-center"
        style={{
          height: '100%',
          alignItems: 'center',
        }}
      >
        <Container>
          <Row>
            <Col md={{ span: 8, offset: 2 }}>
              <Card className="text-center">
                <Card.Body>
                  <img
                    src={ApexLogo}
                    alt={translate('apexLogo')}
                    className="mb-4"
                    style={{ width: '50%', height: 'auto' }}
                  />
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col className="text-center" md={{ span: 8, offset: 2 }}>
              <Card bg="dark">
                <Card.Title>
                  {translate('reportAnIssue')}
                </Card.Title>
                <Card.Body>
                  <p>{translate('contactUsWithProblem')}</p>
                  <div>
                    <a href="mailto:support@apex-mp.com">support@apex-mp.com</a>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default Support;
