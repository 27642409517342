import { configureStore } from '@reduxjs/toolkit';
import { apiSlice } from 'api/apiSlice';
import authSlice from 'localState/authSlice';

export default configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    auth: authSlice,
  },
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat(apiSlice.middleware),
});
