import React, { useState } from 'react';
import { Alert, Col, Row } from 'react-bootstrap';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useResetPasswordMutation } from 'api/authSlice';
import PasswordResetForm from 'common/PasswordResetForm';
import { translate } from '@apex/react-toolkit/lib';
import constants from 'common/constants';
import styles from 'App/AuthRoutes/AuthRoutes.module.css';

const ResetPassword = () => {
  const navigate = useNavigate();
  const { pathname, search } = useLocation();

  const searchParams = new URLSearchParams(search);
  const email = decodeURIComponent(searchParams.get('email') || '');
  const fromVerify = decodeURIComponent(searchParams.get('from-verify') || '');

  const [showVerifySuccess, setShowVerifySuccess] = useState(fromVerify);
  const token = pathname.split('/').pop();
  const [resetPassword, { isSuccess, isLoading, error }] = useResetPasswordMutation();

  if (isSuccess) {
    setTimeout(() => navigate('/login'), constants.redirectTimeoutDuration);
  }

  return (
    <>
      <Row className="justify-content-md-center align-items-center">
        <Col>
          {
            isSuccess && (
              <Alert variant="success">
                <strong>{translate('passwordResetSuccessfully')}</strong>
                <br />
                {translate('redirectingToLogin')} <FontAwesomeIcon icon="spinner" pulse />
              </Alert>
            )
          }
          {
            showVerifySuccess && (
              <Alert variant="success" onClose={() => setShowVerifySuccess(false)} dismissible>
                <strong>{translate('emailVerifiedSuccessfully')}</strong>
                <br />
                {translate('passwordResetRequired')}
              </Alert>
            )
          }
        </Col>
      </Row>
      <Row className="justify-content-md-center align-items-center">
        <Col>
          {
            error && (
              <Alert variant="danger">
                {error.join(' ')}
              </Alert>
            )
          }
          <PasswordResetForm
            disabled={isLoading || isSuccess}
            isLoading={isLoading}
            onSubmit={(formInput) => {
              resetPassword({
                ...formInput,
                token,
                email,
              });
            }}
          />
          <hr />
          <div className="text-center">
            <Link className={styles.authRouteLink} to="/login">{translate('returnToLogin')}</Link>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default ResetPassword;
