import React, { useRef, useState } from 'react';
import { Button, Col, Container, Row, Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { translate } from '@apex/react-toolkit/lib';
import { Spinner } from '@apex/react-toolkit/components';
import ProfileForm from 'common/profile/ProfileForm';
import Card from 'common/Card';
import { useEditMyProfileMutation, useGetMyProfileQuery } from 'api/profileSlice';
import DeleteAvatarModal from 'App/AppRoutes/Profile/DeleteAvatarModal';
import UploadAvatarModal from 'App/AppRoutes/Profile/UploadAvatarModal';

const Profile = () => {
  const navigate = useNavigate();
  const [apiErrors, setApiErrors] = useState(null);
  const [removeAvatarModalOpen, setRemoveAvatarModalOpen] = useState(false);
  const [updateAvatarModalOpen, setUpdateAvatarModalOpen] = useState(false);
  const [updateProfile, { isLoading: updateProfileLoading }] = useEditMyProfileMutation();
  const formRef = useRef();
  const {
    data,
    isLoading: getUserLoading,
  } = useGetMyProfileQuery();

  const handleSubmit = async () => {
    if (formRef.current && formRef.current.values) {
      formRef.current.handleSubmit();

      if (formRef.current.isValid) {
        const { error } = await updateProfile(formRef.current.values);
        // clears previous API errors if any, so they don't hang around after submission
        setApiErrors(null);

        if (error) {
          setApiErrors(error.errors);
        }
      }
    }
  };

  const isLoading = updateProfileLoading || getUserLoading;
  if (!data) return (<Spinner visible size="2x" />);

  const sortedGroups = [...data.groups].sort((a, b) => {
    if (a.name < b.name) return -1;
    if (a.name > b.name) return 1;
    return 0;
  });

  return (
    <Container className="mt-4">
      <DeleteAvatarModal
        show={removeAvatarModalOpen}
        handleClose={() => setRemoveAvatarModalOpen(false)}
      />
      <UploadAvatarModal
        show={updateAvatarModalOpen}
        handleClose={() => setUpdateAvatarModalOpen(false)}
      />
      <Row>
        <Col lg={4} md={12}>
          <Card
            title={translate('editYourProfile')}
            body={(
              <>
                <ProfileForm
                  initialValues={data}
                  disabled={isLoading}
                  apiErrors={apiErrors}
                  ref={formRef}
                />
                <Row className="mt-4">
                  <Col>
                    <Button variant="secondary" onClick={() => navigate('/change-password')} className="me-1">{translate('changePassword')}</Button>
                    <Button variant="primary" onClick={() => handleSubmit()}>{translate('save')}</Button>
                  </Col>
                </Row>
              </>
            )}
          />
        </Col>

        <Col lg={4} md={12}>
          <Card
            title={translate('profilePhoto')}
            body={(
              <>
                <Row className="mt-4">
                  <Col className="text-center">
                    <img src={`/api/platform/users/${data.id}/avatar`} width="160px" alt={translate('userAvatar')} />
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col>
                    <Row>
                      <Button variant="primary" onClick={() => setUpdateAvatarModalOpen(true)}>{translate('uploadPhoto')}</Button>
                    </Row>
                    <Row className="mt-2">
                      <Button
                        variant="danger"
                        onClick={() => setRemoveAvatarModalOpen(true)}
                        disabled={!data.avatar_path}
                      >
                        {translate('deletePhoto')}
                      </Button>
                    </Row>
                  </Col>
                </Row>
              </>
            )}
          />
        </Col>

        <Col lg={4} md={12}>
          <Card
            title={translate('yourGroups')}
            body={(
              <Table variant="dark" striped>
                <tbody>
                  {sortedGroups.map(group => (
                    <tr key={group.id}>
                      <td>{group.name}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}
          />
        </Col>
      </Row>
    </Container >
  );
};

export default Profile;
