import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Home from 'App/AppRoutes/Home';
import Profile from 'App/AppRoutes/Profile';
import ChangePassword from 'App/AppRoutes/ChangePassword';
import PageNotFound from 'common/PageNotFound';

const AppRoutes = () => {
  return (
    <Routes>
      <Route exact path="/" element={<Home />} />
      <Route path="profile/*" element={<Profile />} />
      <Route path="change-password" element={<ChangePassword />} />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};

export default AppRoutes;
