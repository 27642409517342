import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'react-bootstrap';
import { Tile, ImageTile } from '@apex/react-toolkit/components';
import styles from './DraggableTile.module.css';

const DraggableTile = ({ id, url, name, icon, backgroundcolor, textcolor, tileableType, tileableId, innerref, provided, useFavicon }) => {
  return (
    <Col
      sm="14"
      md="4"
      lg="2"
      ref={innerref}
      id={id}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
    >
      {
        tileableType === 'application' ? (
          <ImageTile
            as='a'
            className='mb-4'
            href={url}
            title={name}
            backgroundColor={backgroundcolor}
            textColor={textcolor}
            bodyClassName={styles.tileBody}
            src={`api/platform/applications/${tileableId}/icon-img`}
          />
        ) : (
          <Tile
            className="mb-4"
            bodyClassName={styles.tileBody}
            style={{ textDecoration: 'none' }}
            as="a"
            href={url}
            url={url}
            target={tileableType === 'external_link' ? '_blank' : ''}
            rel="noreferrer noopener"
            title={name}
            icon={icon}
            backgroundcolor={backgroundcolor}
            textcolor={textcolor}
            isExternalLink={tileableType === 'external_link'}
            useFavicon={useFavicon}
          />
        )
      }
    </Col>
  );
};

DraggableTile.propTypes = {
  id: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  backgroundcolor: PropTypes.string.isRequired,
  textcolor: PropTypes.string.isRequired,
  tileableType: PropTypes.string.isRequired,
  innerref: PropTypes.func.isRequired,
  provided: PropTypes.object.isRequired,
  useFavicon: PropTypes.bool.isRequired,
};

export default DraggableTile;
