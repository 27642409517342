import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import { translate } from '@apex/react-toolkit/lib';
import { Tile } from '@apex/react-toolkit/components';
import CreateTileForm from 'App/AppRoutes/Home/CreateTileModal/CreateTileForm';

const CreateTileModal = ({ show, handleSubmit, handleClose, apiErrors, isLoading }) => {
  const formRef = useRef();
  const [tile, setTile] = useState({
    url: '',
    name: '',
    background_color: '#43494F',
    text_color: 'text-light',
    icon: '',
    group: '',
    is_external_link: true,
    use_favicon: true,
  });

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      variant="dark"
      dialogClassName="modal-40w"
    >
      <Modal.Header className="bg-dark text-white">
        <h2>Create New External Link</h2>
      </Modal.Header>
      <Modal.Body className="bg-dark text-white">
        <Row>
          <Col>
            <CreateTileForm
              disabled={isLoading}
              tile={tile}
              setTile={setTile}
              ref={formRef}
              apiErrors={apiErrors}
            />
          </Col>
          <Col md={4} className="ms-2 me-2">
            <h3>Preview</h3>
            <a
              href={tile.url}
              onClick={e => {
                if (!tile.url) e.preventDefault();
              }}
              style={{ textDecoration: 'none' }}
              target="_blank"
              rel="noreferrer noopener"
            >
              <Tile
                title={tile.name || 'Name'}
                icon={tile.icon || 'square'}
                backgroundcolor={tile.background_color}
                textcolor={tile.text_color}
                useFavicon={tile.use_favicon}
                url={tile.url}
                iconSize="10x"
                isExternalLink={tile.is_external_link}
              />
            </a>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer className="bg-dark text-white">
        <Button
          className="me-1"
          disabled={false}
          variant="secondary"
          onClick={handleClose}
        >
          {translate('cancel')}
        </Button>
        <Button
          className="me-auto"
          variant="primary"
          onClick={() => handleSubmit(formRef)}
        >
          {translate('create')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

CreateTileModal.defaultProps = {
  apiErrors: [],
};

CreateTileModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  apiErrors: PropTypes.arrayOf(PropTypes.string),
  isLoading: PropTypes.bool.isRequired,
};

export default CreateTileModal;
