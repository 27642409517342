import React from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from 'yup';
import { translate } from '@apex/react-toolkit/lib';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const PasswordResetForm = ({ onSubmit, disabled, isLoading }) => {
  const schema = yup.object({
    password: yup.string()
      .required(translate('passwordRequired'))
      .min(10)
      .matches(/[a-z]/, translate('passwordMustContainOneLowerCaseLetter'))
      .matches(/[A-Z]/, translate('passwordMustContainOneUpperCaseLetter'))
      .matches(/[0-9]/, translate('passwordMustContainOneNumber'))
      .matches(/[@$!%*#?&]/, translate('passwordMustContainOneSymbol')),
    password_confirmation: yup.string().oneOf([yup.ref('password'), null], translate('passwordsMustMatch')),
  });

  return (
    <Formik
      validationSchema={schema}
      initialValues={{ email: '', first: '', last: '', password: '', password_confirmation: '' }}
      onSubmit={onSubmit}
    >
      {({
        handleSubmit,
        handleChange,
        touched,
        values,
        errors,
      }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Form.Group className="mb-4" controlId="formBasicPassword">
            <Form.Control
              required
              size="lg"
              name="password"
              type="password"
              placeholder="Password"
              value={values.password}
              onChange={handleChange}
              disabled={disabled}
              isValid={touched.password && !errors.password}
            />
            <Form.Control.Feedback type="invalid" className="d-block">
              {errors.password}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-4" controlId="formConfirmPassword">
            <Form.Control
              required
              size="lg"
              name="password_confirmation"
              type="password"
              placeholder="Confirm Password"
              value={values.password_confirmation}
              onChange={handleChange}
              disabled={disabled}
              isValid={touched.password_confirmation && !errors.password_confirmation}
            />
            <Form.Control.Feedback type="invalid" className="d-block">
              {errors.password_confirmation}
            </Form.Control.Feedback>
          </Form.Group>
          <Row className="my-3">
            <Col className="mx-auto">
              <hr className="border border-gray" />
            </Col>
          </Row>
          <Button
            variant="danger"
            type="submit"
            size="lg"
            disabled={disabled}
            className="text-nowrap w-100"
          >
            {isLoading && <FontAwesomeIcon icon="spinner" pulse />} {translate('resetPassword')}
          </Button>
        </Form>
      )}
    </Formik>
  );
};

PasswordResetForm.defaultProps = {
  disabled: false,
};

PasswordResetForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool.isRequired,
};

export default PasswordResetForm;
