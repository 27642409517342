import React, { useState } from 'react';
import {
  Container,
  Row,
  Col,
} from 'react-bootstrap';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import CreateTileModal from 'App/AppRoutes/Home/CreateTileModal';
import {
  useAddFolderMutation,
  useAddTileMutation,
  useDeleteFolderMutation,
  useDeleteTileMutation,
  useGetMyTilesQuery,
  useSortFolderMutation,
  useUpdateTileFolderMutation,
} from 'api/tilesSlice';
import { Error, Spinner } from '@apex/react-toolkit/components';
import { translate } from '@apex/react-toolkit/lib';
import AddDeleteTileTile from 'App/AppRoutes/Home/AddDeleteTileTile';
import Folder from 'App/AppRoutes/Home/Folder';
import CreateNewFolder from 'App/AppRoutes/Home/Folder/CreateNewFolder';
import {
  optimisticSortBetweenFolders,
  optimisticSortFromDefaultFolder,
  optimisticSortInSameFolder,
  optimisticSortIntoPlaceholderFolder,
  optimisticSortIntoPlaceholderFolderFromDefaultFolder,
} from 'helpers/dragAndDrop';
import styles from 'App/AppRoutes/Home/Home.module.css';

const Home = () => {
  const [createTileModalOpen, setCreateTileModalOpen] = useState(false);
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [placeholderFolders, setPlaceholderFolders] = useState([]);
  const [addTile, { isLoading: addTileLoading }] = useAddTileMutation();
  const [updateFolderName, { isLoading: updateFolderNameLoading }] = useUpdateTileFolderMutation();
  const [newTileApiErrors, setNewTileApiErrors] = useState(null);
  const [loadingFolderName, setLoadingFolderName] = useState();
  const { data: folders, isLoading, error: tilesError } = useGetMyTilesQuery();
  const [sortFolders] = useSortFolderMutation();
  const [deleteTile] = useDeleteTileMutation();
  const [deleteFolder] = useDeleteFolderMutation();
  const [addFolder] = useAddFolderMutation();
  const [isDragging, setIsDragging] = useState(false);

  if (isLoading) return (<Spinner coverViewport />);
  if (tilesError) return (<Error error={{ message: translate('errorFetchingDashboardData') }} />);

  const reorderFolder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const removeNewFolderFromPlaceholders = (folderName) => {
    const spliceIndex = placeholderFolders.indexOf(folderName);
    const newFolders = [...placeholderFolders];
    newFolders.splice(spliceIndex, 1);
    setPlaceholderFolders(newFolders);
  };

  const onDragStart = (event) => {
    const flattenedTiles = [];
    folders.forEach((f) => flattenedTiles.push(...f.tiles));
    const draggedItem = flattenedTiles.find((tile) => tile.id === event.draggableId);
    if (draggedItem.tileable_type === 'external_link') {
      setIsDragging(true);
    }
  };

  const onDragEnd = async (result) => {
    if (result.destination === null) return setIsDragging(false); // Bail out if the drop location is invalid

    const aggregatedFolders = [...folders, ...placeholderFolders];
    const sourceFolder = structuredClone(aggregatedFolders.find((f) => f.id === result.source.droppableId || f.name === result.source.droppableId));
    const destinationFolder = structuredClone(aggregatedFolders.find((f) => f.id === result.destination.droppableId || f.name === result.destination.droppableId));

    if (result.destination.droppableId.startsWith('add-delete')) {
      await deleteTile({ id: result.draggableId, folderId: result.source.droppableId });
    } else if (placeholderFolders.find((pf) => pf === result.destination.droppableId)) {
      const [removed] = sourceFolder.tiles.splice(result.source.index, 1);
      if (result.source.droppableId !== 'Default Folder') {
        sourceFolder.tiles.forEach((tile, idx) => {
          tile.index = idx;
        });
        optimisticSortIntoPlaceholderFolder(sourceFolder, result.destination.droppableId, removed);
        removeNewFolderFromPlaceholders(result.destination.droppableId);
        await addFolder({
          tile_id: removed.id,
          name: result.destination.droppableId,
        });
        if (sourceFolder.tiles.length === 0) {
          await deleteFolder({ id: sourceFolder.id });
        } else {
          await sortFolders({ id: sourceFolder.id, tiles: sourceFolder.tiles });
        }
      } else {
        optimisticSortIntoPlaceholderFolderFromDefaultFolder(removed, result.destination.droppableId);
        removeNewFolderFromPlaceholders(result.destination.droppableId);
        await addTile({
          ...removed,
          external_id: removed.id,
          folder: result.destination.droppableId,
          index: 0,
        });
      }
    } else if (result.source.droppableId === result.destination.droppableId) {
      destinationFolder.tiles = reorderFolder(destinationFolder.tiles, result.source.index, result.destination.index);
      destinationFolder.tiles.forEach((tile, idx) => {
        tile.index = idx;
      });
      optimisticSortInSameFolder(destinationFolder);

      await sortFolders(destinationFolder);
    } else if (result.source.droppableId === 'Default Folder') {
      const [removed] = sourceFolder.tiles.splice(result.source.index, 1);
      destinationFolder.tiles.splice(result.destination.index, 0, removed);

      // this re-indexes the tiles so that their indices in the DB are correct.
      // it also adds the folder name to the objects we are sending in the request.
      destinationFolder.tiles.forEach((tile, idx) => {
        tile.index = idx;
      });
      optimisticSortFromDefaultFolder(sourceFolder, destinationFolder);
      await sortFolders({ id: destinationFolder.id, tiles: [...destinationFolder.tiles] });
    } else {
      const [removed] = sourceFolder.tiles.splice(result.source.index, 1);
      destinationFolder.tiles.splice(result.destination.index, 0, removed);

      // this re-indexes the tiles so that their indices in the DB are correct.
      // it also adds the folder name to the objects we are sending in the request.
      destinationFolder.tiles.forEach((tile, idx) => {
        tile.index = idx;
      });
      sourceFolder.tiles.forEach((tile, idx) => {
        tile.index = idx;
      });

      optimisticSortBetweenFolders(sourceFolder, destinationFolder);
      await sortFolders({ id: destinationFolder.id, tiles: [...destinationFolder.tiles] });

      if (sourceFolder.tiles.length === 0) {
        await deleteFolder({ id: sourceFolder.id });
      } else {
        await sortFolders({ id: sourceFolder.id, tiles: [...sourceFolder.tiles] });
      }
    }

    setIsDragging(false);
  };

  const handleSubmitNewTile = async (formRef) => {
    if (formRef.current && formRef?.current.values) {
      formRef.current.handleSubmit();

      if (formRef.current.isValid) {
        const { error } = await addTile({
          ...formRef.current.values,
          folder: selectedFolder.name,
          index: selectedFolder.tiles.length,
          tileable_type: 'external_link',
        });

        if (placeholderFolders.includes(selectedFolder.name)) {
          removeNewFolderFromPlaceholders(selectedFolder.name);
        }

        if (error) {
          setNewTileApiErrors(error.errors);
        } else {
          // clears previous API errors if any, so they don't hang around after submission
          setNewTileApiErrors(null);
          setCreateTileModalOpen(false);
        }
      }
    }
  };

  return (
    <>
      <div className={styles.dashboardBackground}>
      </div>
      <Container className={styles.scrollContainer}>
        <Row className="mt-5">
          <Col>
            {
              createTileModalOpen
              && (
                <CreateTileModal
                  show={createTileModalOpen}
                  handleSubmit={handleSubmitNewTile}
                  handleClose={() => setCreateTileModalOpen(false)}
                  apiErrors={newTileApiErrors}
                  isLoading={addTileLoading}
                />
              )
            }
            <DragDropContext onDragEnd={(e) => onDragEnd(e)} onDragStart={(e) => onDragStart(e)}>
              {
                folders && folders.map(({ id, name, tiles }) => (
                  <Droppable
                    key={name}
                    droppableId={id || name}
                    isDropDisabled={name === 'Default Folder'}
                    direction="horizontal"
                  >
                    {(provided) => (
                      <Folder
                        innerref={provided.innerRef}
                        {...provided.droppableProps}
                        placeholder={provided.placeholder}
                        key={id}
                        name={name}
                        tiles={tiles}
                        isNameLoading={updateFolderNameLoading && loadingFolderName === name}
                        renderAfterTiles={() => (
                          <Droppable key={`add-delete-${name}`} droppableId={`add-delete-${name}`}>
                            {(providedDroppable) => (
                              <>
                                <AddDeleteTileTile
                                  innerref={providedDroppable.innerRef}
                                  {...providedDroppable.droppableProps}
                                  isDragging={isDragging}
                                  isDroppable={isDragging}
                                  handleClick={() => {
                                    setSelectedFolder({ name, tiles });
                                    setCreateTileModalOpen(true);
                                  }}
                                />
                                {providedDroppable.placeholder}
                              </>
                            )}
                          </Droppable>
                        )}
                        onUpdateName={async (formData) => {
                          setLoadingFolderName(name);
                          await updateFolderName({
                            name: formData.name,
                            id,
                          });
                          setLoadingFolderName('');
                        }}
                      />
                    )}
                  </Droppable>
                ))
              }
              {
                placeholderFolders.map((name) => (
                  <Droppable
                    key={name}
                    droppableId={name}
                    direction="horizontal"
                  >
                    {(provided) => (
                      <Folder
                        innerref={provided.innerRef}
                        {...provided.droppableProps}
                        key={name}
                        name={name}
                        tiles={[]}
                        placeholder={provided.placeholder}
                        renderAfterTiles={() => (
                          <Droppable key={`add-delete-${name}`} droppableId={`add-delete-${name}`}>
                            {(providedDroppable) => (
                              <>
                                <AddDeleteTileTile
                                  innerref={providedDroppable.innerRef}
                                  {...providedDroppable.droppableProps}
                                  isDragging={isDragging}
                                  isDroppable={isDragging}
                                  handleClick={() => {
                                    setSelectedFolder({ name, tiles: [] });
                                    setCreateTileModalOpen(true);
                                  }}
                                />
                                {providedDroppable.placeholder}
                              </>
                            )}
                          </Droppable>
                        )}
                        onUpdateName={(formData) => {
                          setLoadingFolderName(name);
                          const index = placeholderFolders.indexOf(name);
                          const newFolders = [...placeholderFolders];
                          newFolders.splice(index, 1, formData.name);
                          setPlaceholderFolders(newFolders);
                          setLoadingFolderName('');
                        }}
                      />
                    )}
                  </Droppable>
                ))
              }
            </DragDropContext>
            <CreateNewFolder
              handleClick={() => setPlaceholderFolders([...placeholderFolders, 'New Folder'])}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Home;
