import React from 'react';
import { Link } from 'react-router-dom';
import { translate } from '@apex/react-toolkit/lib';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { Button, Col, Form, Row } from 'react-bootstrap';
import * as yup from 'yup';
import constants from 'common/constants';
import styles from 'App/AuthRoutes/AuthRoutes.module.css';

const RegistrationForm = ({ initialValues, onSubmit, isLoading, apiErrors }) => {
  const schema = yup.object({
    first_name: yup.string().required(translate('firstNameRequired')),
    last_name: yup.string().required(translate('lastNameRequired')),
    email: yup.string()
      .matches(constants.emailRegex, translate('emailMustBeValid'))
      .required(translate('emailRequired')),
    password: yup.string()
      .required(translate('passwordRequired'))
      .min(10)
      .matches(/[a-z]/, `${translate('password')} ${translate('mustContainOneLowerCase')}`)
      .matches(/[A-Z]/, `${translate('password')} ${translate('mustContainOneUpperCase')}`)
      .matches(/[0-9]/, `${translate('password')} ${translate('mustContainOneNumber')}`)
      .matches(/[@$!%*#?&]/, `${translate('password')} ${translate('mustContainOneSpecialChar')}`),
    password_confirmation: yup.string().oneOf([yup.ref('password'), null], translate('newAndConfirmPasswordMustMatch')),
  });

  return (
    <Formik
      validationSchema={schema}
      initialValues={initialValues}
      onSubmit={(formInput) => {
        onSubmit(formInput);
      }}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        touched,
        values,
        errors,
      }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Form.Group className="mb-4" controlId="first_name">
            <Form.Control
              name="first_name"
              type="text"
              size="lg"
              placeholder={translate('firstName')}
              required
              value={values.first_name}
              onBlur={handleBlur}
              onChange={handleChange}
              disabled={isLoading}
              isValid={touched.first_name && !errors.first_name}
              autoFocus
            />
            {touched.first_name && (errors.first_name || apiErrors?.first_name)
              && (
                <Form.Control.Feedback type="invalid" className="d-block">
                  {errors.first_name || (apiErrors?.first_name && apiErrors.first_name.join(','))}
                </Form.Control.Feedback>
              )
            }
          </Form.Group>
          <Form.Group className="mb-4" controlId="last_name">
            <Form.Control
              as="input"
              name="last_name"
              type="text"
              size="lg"
              placeholder={translate('lastName')}
              required
              value={values.last_name}
              onBlur={handleBlur}
              onChange={handleChange}
              disabled={isLoading}
              isValid={touched.last_name && !errors.last_name}
            />
            {touched.last_name && (errors.last_name || apiErrors?.last_name)
              && (
                <Form.Control.Feedback type="invalid" className="d-block">
                  {errors.last_name || (apiErrors?.last_name && apiErrors.last_name.join(','))}
                </Form.Control.Feedback>
              )
            }
          </Form.Group>
          <Form.Group className="mb-4" controlId="email">
            <Form.Control
              name="email"
              type="email"
              size="lg"
              placeholder={translate('email')}
              required
              value={values.email}
              onBlur={handleBlur}
              onChange={handleChange}
              disabled={isLoading}
              isValid={touched.email && !errors.email}
            />
            {touched.email && (errors.email || apiErrors?.email)
              && (
                <Form.Control.Feedback type="invalid" className="d-block">
                  {errors.email || (apiErrors?.email && apiErrors.email.join(','))}
                </Form.Control.Feedback>
              )
            }
          </Form.Group>
          <Form.Group className="mb-4" controlId="password">
            <Form.Control
              name="password"
              type="password"
              size="lg"
              placeholder={translate('password')}
              required
              value={values.password}
              onBlur={handleBlur}
              onChange={handleChange}
              disabled={isLoading}
              isValid={touched.password && !errors.password}
            />
            {touched.password && (errors.password || apiErrors?.password)
              && (
                <Form.Control.Feedback type="invalid" className="d-block">
                  {errors.password || (apiErrors?.password && apiErrors.password.join(','))}
                </Form.Control.Feedback>
              )
            }
          </Form.Group>
          <Form.Group controlId="password_confirmation">
            <Form.Control
              name="password_confirmation"
              type="password"
              size="lg"
              placeholder={translate('confirmPassword')}
              required
              value={values.password_confirmation}
              onBlur={handleBlur}
              onChange={handleChange}
              disabled={isLoading}
              isValid={touched.password_confirmation && !errors.password_confirmation}
            />
            {touched.password_confirmation && (errors.password_confirmation || apiErrors?.password_confirmation)
              && (
                <Form.Control.Feedback type="invalid" className="d-block">
                  {errors.password_confirmation || (apiErrors?.password_confirmation && apiErrors.password_confirmation.join(','))}
                </Form.Control.Feedback>
              )
            }
          </Form.Group>
          <Row className="my-3">
            <Col className="mx-auto">
              <hr className="border border-gray" />
            </Col>
          </Row>
          <div className="d-grid gap-2">
            <Button
              type="submit"
              variant="danger"
              size="lg"
              disabled={isLoading}
              className="text-nowrap w-100"
            >
              {isLoading && <FontAwesomeIcon icon="spinner" pulse />} {translate('register')}
            </Button>
            <div className="text-center">
              <Link className={styles.authRouteLink} to="/login">{translate('returnToLogin')}</Link>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

RegistrationForm.defaultProps = {
  initialValues: {
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    password_confirmation: '',
  },
  apiErrors: null,
};

RegistrationForm.propTypes = {
  initialValues: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    email: PropTypes.string,
    password: PropTypes.string,
    password_confirmation: PropTypes.string,
  }),
  isLoading: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  apiErrors: PropTypes.shape({
    first_name: PropTypes.arrayOf(PropTypes.string),
    last_name: PropTypes.arrayOf(PropTypes.string),
    email: PropTypes.arrayOf(PropTypes.string),
    password: PropTypes.arrayOf(PropTypes.string),
    password_confirmation: PropTypes.arrayOf(PropTypes.string),
  }),
};

export default RegistrationForm;
