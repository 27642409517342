import Cookies from 'universal-cookie';
import { COOKIE_DOMAIN } from '@apex/react-toolkit/lib';

const cookies = new Cookies();

const cookie = {
  get: (token) => cookies.get(token),
  set: (path, token) => cookies.set(path, token, { path: '/', domain: COOKIE_DOMAIN, maxAge: 86400 }),
};

export default cookie;
