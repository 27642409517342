import React, { useState } from 'react';
import { Alert, Button, Col, Form, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { translate, TOKEN_PATH } from '@apex/react-toolkit/lib';
import { useLoginMutation } from 'api/authSlice';
import { setToken, setUser } from 'localState/authSlice';
import cookie from 'helpers/cookie';
import styles from 'App/AuthRoutes/AuthRoutes.module.css';

const Login = () => {
  const [formState, setFormState] = useState({ username: '', password: '' });
  const [errorMessage, setErrorMessage] = useState('');
  const [login, { isLoading }] = useLoginMutation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleChange = (e) => setFormState({ ...formState, [e.target.name]: e.target.value });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage('');
    const { data, error } = await login({
      username: formState.username,
      password: formState.password,
    });
    if (data && data.auth && data.user) {
      cookie.set(TOKEN_PATH, data.auth.access_token);
      dispatch(setToken(data.auth.access_token));
      dispatch(setUser(data.user));
      navigate('/');
    } else {
      setErrorMessage(error.message || 'could not login');
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      {errorMessage && (
        <Alert variant="danger" onClose={() => setErrorMessage(null)} dismissible>
          <strong>{translate('error')}</strong>
          <br />
          <div dangerouslySetInnerHTML={{ __html: errorMessage }} />
        </Alert>
      )}
      <Form.Group className="mb-4" controlId="username">
        <Form.Control
          name="username"
          type="email"
          size="lg"
          placeholder={translate('email')}
          required
          value={formState.username}
          onChange={handleChange}
          disabled={isLoading}
          autoFocus
        />
      </Form.Group>
      <Form.Group controlId="password">
        <Form.Control
          name="password"
          type="password"
          size="lg"
          placeholder={translate('password')}
          required
          value={formState.password}
          onChange={handleChange}
          disabled={isLoading}
        />
      </Form.Group>
      <Row className="mt-2 mb-3 text-start text-sm">
        <Link to="/forgot-password" className={styles.authRouteLink}>{translate('forgotPassword')}</Link>
      </Row>
      <Row className="my-3">
        <Col className="mx-auto">
          <hr className="border border-gray" />
        </Col>
      </Row>
      <div className="d-grid gap-2">
        <Button
          variant="danger"
          type="submit"
          size="lg"
          disabled={isLoading}
          className="text-nowrap w-100"
        >
          {isLoading && <FontAwesomeIcon icon="spinner" pulse />} {translate('login')}
        </Button>
        <Button
          variant="danger"
          type="button"
          size="lg"
          disabled={isLoading}
          className="text-nowrap w-100"
          onClick={() => navigate('/register')}
        >
          {translate('register')}
        </Button>
      </div>
    </Form>
  );
};

export default Login;
