import React, { useState } from 'react';
import { translate } from '@apex/react-toolkit/lib';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import RegistrationForm from 'common/RegistrationForm';
import constants from 'common/constants';
import { useRegisterUserMutation } from 'api/userSlice';

const Register = () => {
  const navigate = useNavigate();
  const [apiErrors, setApiErrors] = useState(null);
  const [registerUser, { isSuccess, isLoading }] = useRegisterUserMutation();

  if (isSuccess) setTimeout(() => navigate('/login'), constants.redirectTimeoutDuration);

  const handleSubmit = async (formData) => {
    const { error } = await registerUser(formData);
    if (error) {
      setApiErrors(error.errors);
    }
  };

  return (
    <>
      {
        isSuccess
        && (
          <Alert variant="success">
            {translate('registrationSuccessful')} {translate('redirectingToLogin')}
            <br />
            <FontAwesomeIcon icon="spinner" pulse />
          </Alert>
        )
      }
      <RegistrationForm
        isLoading={isLoading || isSuccess}
        onSubmit={handleSubmit}
        apiErrors={apiErrors}
      />
    </>
  );
};

export default Register;
