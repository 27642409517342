import React from 'react';
import { Form } from 'react-bootstrap';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import { translate } from '@apex/react-toolkit/lib';

const FolderNameForm = ({ onSubmit, apiErrors, name }) => {
  const schema = yup.object({
    name: yup.string().required(translate('folderNameRequired')),
  });

  return (
    <div className="w-50">
      <Formik
        validationSchema={schema}
        initialValues={{ name }}
        onSubmit={onSubmit}
      >
        {({
          handleSubmit,
          handleChange,
          touched,
          values,
          errors,
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Form.Group controlId="name">
              <Form.Control
                required
                plaintext
                size="sm"
                name="name"
                type="text"
                placeholder={name}
                value={values.name}
                onChange={handleChange}
                disabled={false}
                isValid={touched.name && !errors.name}
                className="text-light p-0 pt-1"
                style={{ fontSize: '1.25rem' }}
              />
              <Form.Control.Feedback type="invalid" className="d-block">
                {errors.name || (apiErrors?.name && apiErrors.name.join(','))}
              </Form.Control.Feedback>
            </Form.Group>
          </Form>
        )}
      </Formik>
    </div>
  );
};

FolderNameForm.defaultProps = {
  apiErrors: null,
};

FolderNameForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  apiErrors: PropTypes.arrayOf(PropTypes.string),
  name: PropTypes.string.isRequired,
};

export default FolderNameForm;
