// NOTE: These are not valid translations. They were generated with Google translate
// and only serve as a proof of concept
const de = {
  changePassword: 'Passwort Ändern',
  dashboard: 'Armaturenbrett',
  email: 'Email',
  firstName: 'Vorname',
  language: 'Sprache',
  lastName: 'Familienname',
  pageNotFound: 'Seite Nicht Gefunden',
  profileInformation: 'Profil Information',
  saveChanges: 'Änderungen speichern',
};

export default de;
