import { initLocalization } from '@apex/react-toolkit/lib';
import en from './en';
import de from './de';
import ja from './ja';

export default [
  {
    option: 'en',
    display: 'English',
  },
  {
    option: 'de',
    display: 'German (Deustch)',
  },
  {
    option: 'ja',
    display: 'Japanese (日本)',
  },
];

initLocalization({
  en,
  de,
  ja,
});
