import { apiSlice } from 'api/apiSlice';

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getMyTiles: builder.query({
      query: () => ({ url: 'my/tiles', method: 'get' }),
      providesTags: ['Folders'],
    }),

    getFaviconColors: builder.query({
      query: queryParameters => ({ url: 'tiles/favicon-colors', method: 'get', queryParameters }),
    }),

    addTile: builder.mutation({
      query: data => ({ url: 'my/tiles', method: 'post', data }),
      invalidatesTags: () => [{ type: 'Folders' }],
    }),

    addFolder: builder.mutation({
      query: data => ({ url: 'my/folders', method: 'post', data }),
      invalidatesTags: () => [{ type: 'Folders' }],
    }),

    updateTileFolder: builder.mutation({
      query: data => ({ url: `my/folders/${data.id}`, method: 'put', data }),
      invalidatesTags: () => [{ type: 'Folders' }],
    }),

    sortFolder: builder.mutation({
      query: data => ({ url: `my/folders/${data.id}/sort`, method: 'put', data }),
      invalidatesTags: () => [{ type: 'Folders' }],
    }),

    deleteTile: builder.mutation({
      query: data => ({ url: `my/tiles/${data.id}`, method: 'delete' }),
      invalidatesTags: () => [{ type: 'Folders' }],
      async onQueryStarted({ id, folderId }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          apiSlice.util.updateQueryData('getMyTiles', undefined, (draft) => {
            const folderIndex = draft.findIndex((folder) => folder.id === folderId);
            const folder = draft[folderIndex];
            folder.tiles = folder.tiles.filter((tile) => tile.id !== id);
            if (folder.tiles.length > 0) {
              draft.splice(folderIndex, 1, folder);
            } else {
              draft.splice(folderIndex, 1);
            }
          })
        );

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),

    deleteFolder: builder.mutation({
      query: data => ({ url: `my/folders/${data.id}`, method: 'delete' }),
      invalidatesTags: () => [{ type: 'Folders' }],
      async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          apiSlice.util.updateQueryData('getMyTiles', undefined, (draft) => {
            const folderIndex = draft.findIndex((folder) => folder.id === id);
            draft.splice(folderIndex, 1);
          })
        );

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
  }),
});

export const {
  useGetMyTilesQuery,
  useLazyGetFaviconColorsQuery,
  useAddTileMutation,
  useAddFolderMutation,
  useUpdateTileFolderMutation,
  useSortFolderMutation,
  useDeleteTileMutation,
  useDeleteFolderMutation,
} = extendedApiSlice;
