import React from 'react';
import { Link } from 'react-router-dom';
import { Alert, Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { translate } from '@apex/react-toolkit/lib';
import styles from 'App/AuthRoutes/AuthRoutes.module.css';

const ForgotPasswordSuccess = ({ email }) => {
  return (
    <>
      <Alert variant="success">
        <strong>{translate('passwordRecoveryEmailSent')}</strong>
        <br />
        {translate('ifYouHaveAnAccount', { emailAddress: email })}
      </Alert>
      <Row className="my-3">
        <Col className="mx-auto">
          <hr className="border border-gray" />
        </Col>
      </Row>
      <Link className={styles.authRouteLink} to="/login">{translate('returnToLogin')}</Link>
    </>
  );
};

ForgotPasswordSuccess.propTypes = {
  email: PropTypes.string.isRequired,
};

export default ForgotPasswordSuccess;
