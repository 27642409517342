import React, { useCallback, useEffect, useState } from 'react';
import { Formik } from 'formik';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import { Card, Form, InputGroup } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import * as yup from 'yup';
import { translate } from '@apex/react-toolkit/lib';
import { Spinner } from '@apex/react-toolkit/components';
import icons from 'common/externalLinks/icons';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'react-bootstrap-typeahead/css/Typeahead.bs5.css';
import { useLazyGetFaviconColorsQuery } from 'api/tilesSlice';

const textColorOptions = [
  {
    option: 'text-dark',
    display: 'Dark',
  },
  {
    option: 'text-light',
    display: 'Light',
  },
];

const CreateTileForm = React.forwardRef(({ disabled, apiErrors, tile, setTile }, ref) => {
  const schema = yup.object({
    url: yup.string().required(),
    name: yup.string().required(),
    icon: yup.string().oneOf(icons),
    use_favicon: yup.boolean().required(),
    folder: yup.string().required(),
    background_color: yup.string().required(),
  });
  const [url, setUrl] = useState(tile.url);
  const [useCustomColor, setUseCustomColor] = useState(false);
  const [getFaviconColors, { data: palette, isLoading: paletteLoading, isFetching: paletteFetching }] = useLazyGetFaviconColorsQuery();

  const debounceSetTileUrl = useCallback(debounce(value => {
    setTile({ ...tile, url: value });
    getFaviconColors({ url: value });
  }, 500), []);

  useEffect(() => {
    debounceSetTileUrl(url);
  }, [url]);

  const fetchingColorPalette = paletteLoading || paletteFetching;

  return (
    <Formik
      innerRef={ref}
      validationSchema={schema}
      initialValues={tile}
      enableReinitialize
    >
      {({
        touched,
        errors,
      }) => (
        <Form noValidate>
          <Form.Group controlId="formUrl" className="mb-3">
            <Form.Label>{translate('url')}</Form.Label>
            <InputGroup>
              <Form.Control
                autoFocus
                required
                name="url"
                type="text"
                placeholder="https://toyota.com"
                value={url}
                onChange={(e) => {
                  setUrl(e.target.value);
                }}
                disabled={disabled}
                isValid={touched.url && !errors.url}
              />
            </InputGroup>
            <Form.Control.Feedback type="invalid" className="d-block">
              {apiErrors?.url && apiErrors.url.join(',')}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="formName" className="mb-3">
            <Form.Label>{translate('name')}</Form.Label>
            <Form.Control
              required
              name="name"
              type="text"
              placeholder={translate('name')}
              value={tile.name}
              onChange={(e) => {
                setTile({ ...tile, name: e.target.value });
              }}
              disabled={disabled}
              isValid={touched.name && !errors.name}
            />
            <Form.Control.Feedback type="invalid" className="d-block">
              {apiErrors?.name && apiErrors.name.join(',')}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="formUseFavicon" className="mb-3 d-inline-block">
            <Form.Check
              required
              name="use_favicon"
              type="checkbox"
              value={tile.use_favicon}
              label={translate('customIcon')}
              onChange={() => {
                setTile({ ...tile, use_favicon: !tile.use_favicon });
              }}
              disabled={disabled}
              isValid={touched.use_favicon && !errors.use_favicon}
            />
          </Form.Group>

          {
            !tile.use_favicon && (
              <Form.Group controlId="formIcon" className="mb-3">
                <Form.Label>{translate('icon')}</Form.Label>
                <Typeahead
                  id="icon-selector"
                  disabled={disabled}
                  isInvalid={touched.icon && errors.icon}
                  labelKey="icon"
                  options={icons}
                  placeholder={translate('icon')}
                  selected={tile?.icon ? [tile.icon] : []}
                  onChange={(selected) => {
                    if (selected.length === 1) {
                      setTile({ ...tile, icon: selected[0] });
                    } else {
                      setTile({ ...tile, icon: '' });
                    }
                  }}
                />
                <Form.Control.Feedback type="invalid" className="d-block">
                  {apiErrors?.icon && apiErrors.icon.join(',')}
                </Form.Control.Feedback>
              </Form.Group>
            )
          }

          <Form.Group controlId="formBackgroundColor" className="mb-3">
            <Form.Label className="d-block">{translate('backgroundColor')}</Form.Label>
            {
              fetchingColorPalette ? (
                <Spinner size="4x" />
              ) :
                tile.use_favicon && palette && palette.map(color => (
                  <div className="d-block mb-2" key={color}>
                    <Form.Check
                      inline
                      type="radio"
                      value={color}
                      className="radio"
                      name="background_color"
                      onChange={() => {
                        useCustomColor && setUseCustomColor(false);
                        setTile({ ...tile, background_color: color });
                      }}
                    />
                    <Card className="d-inline-block align-middle" style={{ width: '2rem', height: '2rem', backgroundColor: color }}></Card>
                  </div>
                ))
            }
            <Form.Check
              inline
              type="radio"
              value='Custom Color'
              label="Custom Color"
              className="radio mb-2"
              name="background_color"
              onChange={() => {
                setUseCustomColor(true);
              }}
            />
            {
              (useCustomColor || !tile.use_favicon) && (
                <Form.Control
                  required
                  name="background_color"
                  type="color"
                  label="Custom Color"
                  placeholder={translate('backgroundColor')}
                  value={tile.background_color}
                  onChange={(e) => {
                    setTile({ ...tile, background_color: e.target.value });
                  }}
                  disabled={disabled}
                  isValid={touched.background_color && !errors.background_color}
                />
              )
            }
            <Form.Control.Feedback type="invalid" className="d-block">
              {apiErrors?.background_color && apiErrors.background_color.join(',')}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="formTextColor" className="mb-3">
            <Form.Label>{translate('textColor')}</Form.Label>
            <Form.Select
              required
              name="text_color"
              type="text"
              placeholder={translate('textColor')}
              value={tile.text_color}
              onChange={(e) => {
                setTile({ ...tile, text_color: e.target.value });
              }}
              disabled={disabled}
              isValid={touched.background_color && !errors.background_color}
            >
              {textColorOptions.map(({ option, display }) => (
                <option key={option} value={option}>
                  {display}
                </option>
              ))}

            </Form.Select>
            <Form.Control.Feedback type="invalid" className="d-block">
              {apiErrors?.text_color && apiErrors.text_color.join(',')}
            </Form.Control.Feedback>
          </Form.Group>
        </Form>
      )}
    </Formik>
  );
});

CreateTileForm.defaultProps = {
  apiErrors: null,
};

CreateTileForm.propTypes = {
  apiErrors: PropTypes.shape({
    url: PropTypes.arrayOf(PropTypes.string),
    name: PropTypes.arrayOf(PropTypes.string),
    background_color: PropTypes.arrayOf(PropTypes.string),
    text_color: PropTypes.arrayOf(PropTypes.string),
    icon: PropTypes.arrayOf(PropTypes.string),
  }),
  tile: PropTypes.shape({
    url: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    background_color: PropTypes.string.isRequired,
    text_color: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    group: PropTypes.string.isRequired,
  }).isRequired,
  disabled: PropTypes.bool.isRequired,
  setTile: PropTypes.func.isRequired,
};

export default CreateTileForm;
