const en = {
  // Labels, Headers, etc
  apexLogo: 'Apex Logo',
  backgroundColor: 'Background Color',
  cancel: 'Cancel',
  changePassword: 'Change Password',
  checkYourSpamFolder: 'Make sure you check your spam folder, then go to',
  confirmDeleteTileGroup: 'Are you sure you want to delete the :tileGroup: tile group?',
  confirmRemoveProfilePhoto: 'Are you sure you want to remove your profile photo?',
  confirmPassword: 'Confirm Password',
  contactUsWithProblem: 'If you\'re having trouble with Apex please email us.',
  create: 'Create',
  cropBox: 'A cropping tool for the user avatar',
  currentPassword: 'Current Password',
  customIcon: 'Custom Icon',
  dashboard: 'Dashboard',
  delete: 'Delete',
  deletePhoto: 'Delete Photo',
  deleteTileGroup: 'Delete Tile Group',
  editYourProfile: 'Edit Your Profile',
  email: 'Email',
  emailVerifiedSuccessfully: 'Email verified successfully',
  error: 'Error',
  errorAddingTileToNewGroup: 'Unable to add tile to new group.',
  errorFetchingDashboardData: 'We encountered an error when loading your profile data.',
  errorRemovingTileFromPreviousTileGroup: 'Unable to remove tile from previous tile group.',
  firstName: 'First Name',
  forgotPassword: 'Forgot Password?',
  group: 'Group',
  icon: 'Icon',
  ifYouHaveAnAccount: 'An email was sent to :emailAddress:. If you have an account with us under that email, it will arrive shortly.',
  language: 'Language',
  lastName: 'Last Name',
  login: 'Login',
  name: 'Name',
  newGroup: 'New Group',
  newPassword: 'New Password',
  newVerificationEmailSent: 'Your email was not able to be verified. A new verification email has been sent. Please check your inbox.',
  pageNotFound: 'Page Not Found',
  password: 'Password',
  passwordRecoveryEmailSent: 'Password Recovery Email Sent',
  passwordResetRequested: 'Password Reset has been requested. Check your email.',
  passwordResetRequired: 'You are required to reset your password.',
  passwordResetSuccessfully: 'Password Reset Successfully',
  profile: 'Profile',
  profilePhoto: 'Profile Photo',
  register: 'Register',
  registrationSuccessful: 'Registration successful. A verification email has been sent to the provided address.',
  remove: 'Remove',
  redirectingToLogin: 'Redirecting to Login',
  removeProfilePhoto: 'Remove Profile Photo',
  reportAnIssue: 'Report an issue',
  resetPassword: 'Reset Password',
  returnToLogin: 'Return To Login',
  save: 'Save',
  sendNewVerificationEmail: 'Send New Verification Email',
  sendPasswordRecoveryEmail: 'Send Password Recovery Email',
  submit: 'Submit',
  textColor: 'Text Color',
  thisOperationCannotBeUndone: 'This operation cannot be undone!',
  updateTileGroupsError: 'An error occurred while updating your tile groups.',
  uploadPhoto: 'Upload Photo',
  uploadProfilePhoto: 'Upload Profile Photo',
  url: 'URL',
  userAvatar: 'User Avatar',
  verificationFailed: 'Verification Failed',
  verificationNotFound: 'We were not able to locate the provided email verification token. Please enter your email below, and we will generate a new one if the account exists.',
  verifyMyEmail: 'Verify my email address',
  yourGroups: 'Your Groups',

  // Validation messages
  currentPasswordRequired: 'Current password is required',
  emailMustBeValid: 'Email must be a valid email',
  emailRequired: 'Email is required',
  firstNameRequired: 'First Name is required',
  mustContainOneLowerCase: 'must contain at least one lower case letter',
  mustContainOneUpperCase: 'must contain at least one upper case letter',
  mustContainOneNumber: 'must contain at least one number',
  mustContainOneSpecialChar: 'must contain at least one of the following special characters (@$!%*#?&)',
  newAndConfirmPasswordMustMatch: 'New and confirm passwords must match',
  newPasswordRequired: 'New password is required',
  lastNameRequired: 'Last Name is required',
  linkColorRequired: 'Color is required',
  linkGroupRequired: 'Group is required',
  linkIconRequired: 'An icon is required',
  linkNameRequired: 'Name is required',
  linkUrlRequired: 'URL is required',
  localeRequired: 'Locale is required',
  passwordMustContainOneLowerCaseLetter: 'Password must contain at least one lower case letter',
  passwordMustContainOneNumber: 'Password must contain at least one number',
  passwordMustContainOneSymbol: 'Password must contain at least one of the following special characters (@$!%*#?&)',
  passwordMustContainOneUpperCaseLetter: 'Password must contain at least one upper case letter',
  passwordsMustMatch: 'Passwords must match',
  passwordRequired: 'Password is required',
  folderNameRequired: 'Folder Name is required',
};

export default en;
