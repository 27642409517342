import { apiSlice } from 'api/apiSlice';

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({

    registerUser: builder.mutation({
      query: data => ({ url: 'register', method: 'post', data }),
      invalidatesTags: () => [{ type: 'Users' }],
    }),

    getUser: builder.query({
      query: id => ({ url: `users/${id}`, method: 'get' }),
      providesTags: (result, error, id) => [{ type: 'Users', id }],
    }),

    editUser: builder.mutation({
      query: data => ({ url: `users/${data.id}`, method: 'put', data }),
      invalidatesTags: (result, error, { id }) => [{ type: 'Users', id }],
    }),
  }),
});

export const {
  useRegisterUserMutation,
  useGetUserQuery,
  useEditUserMutation,
} = extendedApiSlice;
