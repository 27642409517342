import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';
import { translate } from '@apex/react-toolkit/lib';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDeleteMyAvatarMutation } from 'api/profileSlice';
import { Error } from '@apex/react-toolkit/components';

const DeleteAvatarModal = ({ show, handleClose }) => {
  const [deleteMyAvatar, { isLoading, data, error }] = useDeleteMyAvatarMutation();

  useEffect(() => {
    if (data && !isLoading && !error) window.location.reload();
  }, [data, error]);
  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header className="border-0 bg-dark text-light">
        <h2>{translate('removeProfilePhoto')}</h2>
      </Modal.Header>
      <Modal.Body className="confirm bg-dark text-light">
        <p>
          {translate('confirmRemoveProfilePhoto')}
        </p>
        <p>
          {translate('thisOperationCannotBeUndone')}
        </p>
        {error && <Error error={error} />}
      </Modal.Body>
      <Modal.Footer className="border-0 py-3 justify-content-start bg-dark text-light">
        <Button
          className="me-1"
          disabled={false}
          variant="secondary"
          onClick={handleClose}
        >
          {translate('cancel')}
        </Button>
        <Button
          disabled={isLoading}
          variant="danger"
          onClick={async () => {
            deleteMyAvatar();
          }}
        >
          {isLoading && <FontAwesomeIcon icon="spinner" pulse />} <FontAwesomeIcon icon="trash" /> {translate('deletePhoto')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

DeleteAvatarModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default DeleteAvatarModal;
