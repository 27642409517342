import { apiSlice } from 'api/apiSlice';

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (credentials) => ({
        url: 'login',
        method: 'POST',
        authenticated: false,
        data: credentials,
      }),
    }),

    forgotPassword: builder.mutation({
      query: (credentials) => ({
        url: 'forgot-password',
        method: 'post',
        authenticated: false,
        data: credentials,
      }),
    }),

    resetPassword: builder.mutation({
      query: (data) => ({
        url: 'reset-password',
        method: 'post',
        authenticated: false,
        data,
      }),
    }),

    changePassword: builder.mutation({
      query: (data) => ({
        url: 'change-password',
        method: 'post',
        data,
      }),
    }),

    getNewEmailVerification: builder.mutation({
      query: data => ({ url: `email/verify/new`, method: 'post', data }),
    }),

    submitVerification: builder.mutation({
      query: ({ token }) => ({
        url: `email/verify/${token}`,
        method: 'post',
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation,
  useChangePasswordMutation,
  useGetNewEmailVerificationMutation,
  useSubmitVerificationMutation,
} = extendedApiSlice;
