import React, { useEffect } from 'react';
import { Tooltip } from 'bootstrap';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from 'App/AppRoutes/Home/Folder/CreateNewFolder/CreateNewFolder.module.css';
import PropTypes from 'prop-types';

const CreateNewFolder = ({ handleClick }) => {
  useEffect(() => {
    const tooltipTrigger = document.getElementById('createButtonForFolder');
    new Tooltip(tooltipTrigger, { trigger: 'hover' });
  }, []);

  return (
    <div className={`${styles.createContainer} d-flex py-5 justify-content-center`}>
      <Button
        id="createButtonForFolder"
        variant="success"
        className="rounded-circle"
        onClick={() => handleClick()}
        title="Create new folder"
      >
        <FontAwesomeIcon icon="plus" size="4x" />
      </Button>
    </div>
  );
};

CreateNewFolder.propTypes = {
  handleClick: PropTypes.func.isRequired,
};

export default CreateNewFolder;
