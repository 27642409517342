import React from 'react';
import { Modal } from 'react-bootstrap';
import { Route, Routes } from 'react-router-dom';

import Login from 'App/AuthRoutes/Login';
import ForgotPassword from 'App/AuthRoutes/ForgotPassword';
import Register from 'App/AuthRoutes/Register';
import ResetPassword from 'App/AuthRoutes/ResetPassword';
import VerificationSuccess from 'App/AuthRoutes/VerificationSuccess';
import VerificationFail from 'App/AuthRoutes/VerificationFail';
import styles from 'App/AuthRoutes/AuthRoutes.module.css';
import ApexLogo from 'images/apex_logo_sm.png';
import { translate } from '@apex/react-toolkit/lib';
import VerificationNotFound from 'App/AuthRoutes/VerificationNotFound';
import VerificationSubmit from 'App/AuthRoutes/VerificationSubmit';

const AuthRoutes = () => (
  <div className={styles.loginBackground}>
    <div
      className="d-flex justify-content-center"
      style={{
        height: '100%',
        alignItems: 'center',
      }}
    >
      <Modal
        show
        bg="light"
        text="dark"
        backdrop={false}
        keyboard={false}
        centered
      >
        <Modal.Body className="text-center">
          <img
            src={ApexLogo}
            alt={translate('apexLogo')}
            className="mb-4"
            style={{ width: '50%', height: 'auto' }}
          />
          <Routes>
            <Route exact path="/login" element={<Login />} />
            <Route exact path="/register" element={<Register />} />
            <Route exact path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password/*" element={<ResetPassword />} />
            <Route exact path="/verify/email/success" element={<VerificationSuccess />} />
            <Route exact path="/verify/email/fail" element={<VerificationFail />} />
            <Route exact path="/verify/email/not-found" element={<VerificationNotFound />} />
            <Route path="/verify/email/:token" element={<VerificationSubmit />} />
            <Route path="*" element={<Login />} />
          </Routes>
        </Modal.Body>
      </Modal>
    </div>
  </div>
);

export default AuthRoutes;
