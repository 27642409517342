const icons = [
  'abacus',
  'address-book',
  'address-card',
  'alarm-clock',
  'alien',
  'alien-8bit',
  'analytics',
  'anchor',
  'aperture',
  'archway',
  'atlas',
  'atom',
  'bacon',
  'bat',
  'battery-bolt',
  'bell',
  'bell-exclamation',
  'bell-on',
  'bicycle',
  'biohazard',
  'bolt',
  'bookmark',
  'broadcast-tower',
  'car',
  'car-battery',
  'car-bump',
  'car-crash',
  'car-mechanic',
  'clipboard-list',
  'clipboard-list-check',
  'coffee-pot',
  'comment-alt-dots',
  'comment-lines',
  'comment-captions',
  'compass',
  'desktop',
  'dollar-sign',
  'dolly',
  'euro-sign',
  'external-link',
  'file-alt',
  'film',
  'first-aid',
  'folder',
  'folder-download',
  'function',
  'globe',
  'head-side-brain',
  'home',
  'house-signal',
  'inbox',
  'infinity',
  'jedi',
  'keyboard',
  'laptop',
  'link',
  'location-arrow',
  'luchador',
  'medal',
  'newspaper',
  'passport',
  'person',
  'person-biking-mountain',
  'person-dress',
  'radiation-alt',
  'radio',
  'random',
  'raygun',
  'receipt',
  'rocket',
  'rocket-launch',
  'server',
  'signal-alt',
  'tachometer',
  'tachometer-alt-fast',
  'tachometer-alt-fastest',
  'trophy',
  'truck',
  'user',
  'user-astronaut',
  'video',
  'warehouse',
  'wifi',
  'wrench',
  'yen-sign',
];


export default icons;
