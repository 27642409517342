// NOTE: These are not valid translations. They were generated with Google translate
// and only serve as a proof of concept
const ja = {
  changePassword: 'パスワードを変更する',
  dashboard: '',
  email: 'Eメール',
  firstName: 'ファーストネーム',
  language: '言語',
  lastName: '苗字',
  pageNotFound: 'ページが見つかりません',
  profileInformation: 'プロファイル情報',
  saveChanges: '変更内容を保存',
};

export default ja;
