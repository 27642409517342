import { apiSlice } from 'api/apiSlice';

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getMyProfile: builder.query({
      query: () => ({ url: 'my/user', method: 'get' }),
      providesTags: (result, error, id) => [{ type: 'Profile', id }],
    }),

    addMyAvatar: builder.mutation({
      query: data => ({ url: 'my/avatar', method: 'post', data, isFileUpload: true }),
      invalidatesTags: () => [{ type: 'Profile' }],
    }),

    deleteMyAvatar: builder.mutation({
      query: data => ({ url: 'my/avatar', method: 'delete', data }),
      invalidatesTags: () => [{ type: 'Profile' }],
    }),

    editMyProfile: builder.mutation({
      query: data => ({ url: `my/profile`, method: 'put', data }),
      invalidatesTags: (result, error, { id }) => [{ type: 'Users', id }],
    }),
  }),
});

export const {
  useGetMyProfileQuery,
  useAddMyAvatarMutation,
  useDeleteMyAvatarMutation,
  useEditMyProfileMutation,
} = extendedApiSlice;
