import qs from 'query-string';
import { createApi } from '@reduxjs/toolkit/dist/query/react';

const { protocol, host } = window.location;

export const baseUrl = host.startsWith('localhost') ? 'http://localhost/api/platform' : `${protocol}//${host}/api/platform`;
const customBaseQuery = async ({ url, method = 'get', data, queryParameters, isFileUpload = false }) => {
  const fetchOptions = {
    method,
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    },
  };

  let queryString = '';
  if (data !== undefined) {
    if (isFileUpload) {
      fetchOptions.body = data;
      delete fetchOptions.headers['Content-Type'];
    } else {
      fetchOptions.body = JSON.stringify(data);
    }
  }
  if (queryParameters) queryString = `?${qs.stringify(queryParameters)}`;
  const response = await fetch(`${baseUrl}/${url}${queryString}`, fetchOptions);

  if (response.status === 401) {
    document.dispatchEvent(new CustomEvent('platform.401'));
  } else if (response.status === 204 || response.status === 201) {
    return { data: {} };
  } else if (response.ok) {
    const originalResponse = await response.json();
    if (originalResponse.meta) {
      return {
        data: {
          meta: originalResponse.meta,
          data: originalResponse.data,
          links: originalResponse.links,
        },
      };
    }

    return originalResponse;
  }

  const errBody = await response.json();
  return {
    error: errBody,
  };
};

export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: customBaseQuery,
  tagTypes: [
    'Users',
    'Profile',
    'Folders',
  ],
  // eslint-disable-next-line no-unused-vars
  endpoints: () => ({}),
});

export default apiSlice;
