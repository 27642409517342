import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Form,
  Button,
} from 'react-bootstrap';
import { translate } from '@apex/react-toolkit/lib';
import localeOptions from 'localization';

const ApexSettings = () => {
  const [currentLocale, setCurrentLocale] = useState(localeOptions[0]);
  return (
    <Form onSubmit={e => e.preventDefault()}>
      <Form.Group className="mb-3">
        <Form.Select size="sm" value={currentLocale} onChange={e => setCurrentLocale(e.target.value)}>
          {localeOptions.map(locale => <option key={locale.option} value={locale.option}>{locale.display}</option>)}
        </Form.Select>
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Check
          type="switch"
          id="browser-notifications-switch"
          label="Browser Notifications"
          defaultChecked
        />
      </Form.Group>

      <Form.Group className="mb-3 text-end">
        <Button variant="primary" size="sm" type="submit">
          <FontAwesomeIcon icon="floppy-disk" /> {translate('save')}
        </Button>
      </Form.Group>
    </Form>
  );
};

export default ApexSettings;
