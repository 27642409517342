import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { Form } from 'react-bootstrap';
import * as yup from 'yup';
import { translate } from '@apex/react-toolkit/lib';
import localeOptions from 'localization';

const ProfileForm = React.forwardRef(({ initialValues, disabled, apiErrors }, ref) => {
  const schema = yup.object({
    email: yup.string().required(translate('emailRequired')),
    first_name: yup.string().required(translate('firstNameRequired')),
    last_name: yup.string().required(translate('lastNameRequired')),
    locale: yup.string().required(translate('localeRequired')),
  });

  return (
    <Formik
      innerRef={ref}
      validationSchema={schema}
      initialValues={initialValues}
      enableReinitialize
    >
      {({
        handleChange,
        touched,
        values,
        errors,
      }) => (
        <Form noValidate>
          <Form.Group className="mb-3" controlId="formEmail">
            <Form.Label>{translate('email')}</Form.Label>
            <Form.Control
              required
              name="email"
              type="text"
              placeholder="Email"
              value={values.email}
              onChange={handleChange}
              disabled={disabled}
              isValid={touched.email && !errors.email}
              size="sm"
            />
            <Form.Control.Feedback type="invalid" className="d-block">
              {errors.email || (apiErrors?.email && apiErrors.email.join(','))}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3" controlId="formFirstName">
            <Form.Label>{translate('firstName')}</Form.Label>
            <Form.Control
              required
              name="first_name"
              type="text"
              placeholder="First Name"
              value={values.first_name}
              onChange={handleChange}
              disabled={disabled}
              isValid={touched.first_name && !errors.first_name}
              size="sm"
            />
            <Form.Control.Feedback type="invalid" className="d-block">
              {errors.first_name || (apiErrors?.first_name && apiErrors.first_name.join(','))}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3" controlId="formLastName">
            <Form.Label>{translate('lastName')}</Form.Label>
            <Form.Control
              required
              name="last_name"
              type="text"
              placeholder="Last Name"
              value={values.last_name}
              onChange={handleChange}
              disabled={disabled}
              isValid={touched.last_name && !errors.last_name}
              size="sm"
            />
            <Form.Control.Feedback type="invalid" className="d-block">
              {errors.last_name || (apiErrors?.last_name && apiErrors.last_name.join(','))}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3" controlId="formLanguage">
            <Form.Label>{translate('language')}</Form.Label>
            <Form.Select
              required
              name="locale"
              placeholder="Select a language"
              value={values.locale}
              onChange={handleChange}
              disabled={disabled}
              isValid={touched.locale && !errors.locale}
              size="sm"
            >
              {localeOptions.map(({ option, display }) => (
                <option key={option} value={option}>
                  {display}
                </option>
              ))}
            </Form.Select>
            <Form.Control.Feedback type="invalid" className="d-block">
              {errors.locale || (apiErrors?.locale && apiErrors.locale.join(','))}
            </Form.Control.Feedback>
          </Form.Group>
        </Form>
      )}
    </Formik>
  );
});

ProfileForm.defaultProps = {
  apiErrors: null,
};

ProfileForm.propTypes = {
  initialValues: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    email: PropTypes.string,
    locale: PropTypes.string,
  }).isRequired,
  apiErrors: PropTypes.shape({
    first_name: PropTypes.arrayOf(PropTypes.string),
    last_name: PropTypes.arrayOf(PropTypes.string),
    email: PropTypes.arrayOf(PropTypes.string),
    locale: PropTypes.arrayOf(PropTypes.string),
  }),
  disabled: PropTypes.bool.isRequired,
};

export default ProfileForm;
