import React, { useRef, useState } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { translate } from '@apex/react-toolkit/lib';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import ChangePasswordForm from 'common/ChangePasswordForm';
import { useChangePasswordMutation } from 'api/authSlice';

const ChangePassword = () => {
  const [apiErrors, setApiErrors] = useState(null);
  const [changePassword, { isLoading }] = useChangePasswordMutation();
  const navigate = useNavigate();
  const formRef = useRef();
  const initialValues = {
    current_password: '',
    new_password: '',
    password_confirmation: '',
  };

  const handleSubmit = async () => {
    if (formRef.current && formRef.current.values) {
      formRef.current.handleSubmit();

      if (formRef.current.isValid) {
        const { error } = await changePassword(formRef.current.values);
        // clears previous API errors if any, so they don't hang around after submission
        setApiErrors(null);

        if (error) {
          setApiErrors(error.errors);
        } else {
          navigate('/profile');
        }
      }
    }
  };

  return (
    <Container className="mt-4">
      <Row>
        <Col md={4}>
          <Row>
            <Col>
              <h1>{translate('changePassword')}</h1>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col>
              <ChangePasswordForm
                initialValues={initialValues}
                disabled={isLoading}
                apiErrors={apiErrors}
                ref={formRef}
              />
            </Col>
          </Row>
          <Row className="mt-4">
            <Col>
              <Button
                variant="primary"
                className="me-2"
                onClick={() => handleSubmit()}
                disabled={isLoading}
              >
                {isLoading && <FontAwesomeIcon icon="spinner" pulse />} {translate('save')}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default ChangePassword;
