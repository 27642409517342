import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form, Modal, Row, Button } from 'react-bootstrap';
import { translate } from '@apex/react-toolkit/lib';
import { Error } from '@apex/react-toolkit/components';
import Cropper from 'react-easy-crop';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAddMyAvatarMutation } from 'api/profileSlice';
import getCroppedImg from './cropImage';

const UploadAvatarModal = ({ show, handleClose }) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [avatar, setAvatar] = useState(null);
  const [completedCrop, setCompletedCrop] = useState();
  const [addMyAvatar, { isLoading, data, error }] = useAddMyAvatarMutation();
  const [validationError, setValidationError] = useState(null);

  const onCropComplete = React.useCallback(async (croppedArea, croppedAreaPixels) => {
    const croppedImage = await getCroppedImg(avatar, croppedAreaPixels);
    setCompletedCrop(croppedImage);
  }, [avatar]);

  // Closes the modal on successful save
  useEffect(() => {
    if (data && !error) window.location.reload();
  }, [data, error]);

  const saveCroppedAvatar = () => {
    //                   KB     MB
    const maxFileSize = 3072 * 1000;
    const avatarFile = new File([completedCrop], 'avatar.jpg');
    const form = new FormData();
    form.append('avatar', avatarFile);
    if (avatarFile.size > maxFileSize) {
      setValidationError('Cropped image exceeds max file size 3MB');
    } else {
      addMyAvatar(form);
    }
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header className="border-0 bg-dark text-white">
        <h2>{translate('uploadProfilePhoto')}</h2>
      </Modal.Header>
      <Modal.Body className="confirm bg-dark text-white">
        <Row>
          <Form.Group controlId="formFileSm" className="mb-3">
            <Form.Control
              onChange={(e) => {
                const file = e.target.files[0];
                const reader = new FileReader();
                setValidationError(null);
                reader.addEventListener('load', () => setAvatar(reader.result || ''));
                reader.readAsDataURL(file);
                setAvatar(file);
              }}
              type="file"
              name="avatar"
              size="sm"
              className="mb-2"
              accept=".png, .jpg, .jpeg"
            />
            {(error || validationError) && <Error error={error || { message: validationError }} />}
          </Form.Group>
        </Row>
        {avatar && (
          <Row className="position-relative" style={{ height: 300 }}>
            <Cropper
              image={avatar}
              crop={crop}
              zoom={zoom}
              aspect={4 / 3}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
            />
          </Row>
        )}
      </Modal.Body>
      <Modal.Footer className="border-0 py-3 justify-content-start bg-dark text-white">
        <Button
          className="me-1"
          disabled={false}
          variant="secondary"
          onClick={() => {
            setAvatar(null);
            handleClose();
          }}
        >
          {translate('cancel')}
        </Button>
        <Button
          disabled={!avatar || isLoading}
          variant="primary"
          type="submit"
          onClick={saveCroppedAvatar}
        >
          {isLoading && <FontAwesomeIcon icon="spinner" pulse />} {translate('save')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

UploadAvatarModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default UploadAvatarModal;
