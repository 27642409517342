import { apiSlice } from 'api/apiSlice';
import store from 'store';

/**
 * This method is to calculate and update the new state
 * for sorting tiles between folders
 *
 * @param sourceFolder - the source folder for the drag event
 * @param destinationFolder - the destination folder for the drag event
 * @return void
 */
export const optimisticSortBetweenFolders = (sourceFolder, destinationFolder) => {
  store.dispatch(
    apiSlice.util.updateQueryData('getMyTiles', undefined, (draft) => {
      const sourceFolderIndex = draft.findIndex((folder) => folder.id === sourceFolder.id);
      const destinationFolderIndex = draft.findIndex((folder) => folder.id === destinationFolder.id);
      draft.splice(destinationFolderIndex, 1, destinationFolder);

      if (sourceFolder.tiles.length === 0) {
        draft.splice(sourceFolderIndex, 1);
      } else {
        draft.splice(sourceFolderIndex, 1, sourceFolder);
      }
    })
  );
};

/**
 * This method is to calculate and update the new state
 * for sorting tiles in the same folder
 *
 * @param folder - the source (and destination) folder for the drag event
 * @return void
 */
export const optimisticSortInSameFolder = (folder) => {
  store.dispatch(
    apiSlice.util.updateQueryData('getMyTiles', undefined, (draft) => {
      const folderIndex = draft.findIndex((f) => f.id === folder.id);
      draft.splice(folderIndex, 1, folder);
    })
  );
};

/**
 * This method is to calculate and update the new state
 * for sorting applications out of the Default Folder
 *
 * @param destinationFolder - the source (and destination) folder for the drag event
 * @param sortedTile - the tile that was removed from the default folder
 * @return void
 */
export const optimisticSortFromDefaultFolder = (defaultFolder, destinationFolder) => {
  store.dispatch(
    apiSlice.util.updateQueryData('getMyTiles', undefined, (draft) => {
      const folderIndex = draft.findIndex((f) => f.id === destinationFolder.id);
      const defaultFolderIndex = draft.findIndex((f) => f.name === 'Default Folder');
      draft.splice(folderIndex, 1, destinationFolder);

      if (defaultFolder.tiles.length === 0) {
        draft.splice(defaultFolderIndex, 1);
      } else {
        draft.splice(defaultFolderIndex, 1, defaultFolder);
      }
    })
  );
};

/**
 * This method is to calculate and update the new state
 * for sorting applications into a new folder.
 *
 * @param destinationFolder - the source (and destination) folder for the drag event
 * @param sortedTile - the tile that was removed from the default folder
 * @return void
 */
export const optimisticSortIntoPlaceholderFolder = (sourceFolder, newFolder, sortedTile) => {
  store.dispatch(
    apiSlice.util.updateQueryData('getMyTiles', undefined, (draft) => {
      const sourceFolderIndex = draft.findIndex((folder) => folder.id === sourceFolder.id);
      if (sourceFolder.tiles.length === 0) {
        draft.splice(sourceFolderIndex, 1);
      } else {
        draft.splice(sourceFolderIndex, 1, sourceFolder);
      }
      draft.push({
        name: newFolder,
        tiles: [
          sortedTile,
        ],
      });
    })
  );
};

/**
 * This method is to calculate and update the new state
 * for sorting applications into a new folder from the default folder.
 *
 * @param destinationFolder - the source (and destination) folder for the drag event
 * @param sortedTile - the tile that was removed from the default folder
 * @return void
 */
export const optimisticSortIntoPlaceholderFolderFromDefaultFolder = (sortedTile, newFolder) => {
  store.dispatch(
    apiSlice.util.updateQueryData('getMyTiles', undefined, (draft) => {
      const defaultFolderIndex = draft.findIndex((f) => f.name === 'Default Folder');
      const defaultFolder = draft[defaultFolderIndex];
      const sortedTileIndex = defaultFolder.tiles.findIndex((t) => t.id === sortedTile.id);
      defaultFolder.tiles.splice(sortedTileIndex, 1);
      draft.splice(defaultFolderIndex, 1, defaultFolder);
      draft.push({
        name: newFolder,
        tiles: [
          sortedTile,
        ],
      });
    })
  );
};
