import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Draggable } from 'react-beautiful-dnd';
import PropTypes from 'prop-types';
import { Spinner } from '@apex/react-toolkit/components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DraggableTile from 'App/AppRoutes/Home/DraggableTile';
import FolderNameForm from 'common/folders/FolderNameForm';

const Folder = ({ name, tiles, renderAfterTiles, onUpdateName, isNameLoading, innerref, placeholder }) => {
  const isDefaultFolder = name === 'Default Folder';

  return (
    <fieldset className={`px-4 ${isDefaultFolder ? 'pt-4 pb-4' : 'pt-1 pb-1 border'} mb-4 bg-black bg-opacity-75`} ref={innerref}>
      <Row>
        <Col>
          {
            !isDefaultFolder && (
              <legend>
                <div className="d-flex w-100">
                  {
                    isNameLoading
                      ? <Spinner />
                      : <FontAwesomeIcon className="me-2 mt-2" icon="folder" />
                  }
                  <FolderNameForm
                    name={name}
                    onSubmit={onUpdateName}
                  />
                </div>
              </legend>
            )
          }
        </Col>
      </Row>
      <Row>
        {tiles && tiles.map(({
          id: tileId,
          url,
          name: tileName,
          icon,
          background_color: backgroundcolor,
          text_color: textcolor,
          tileable_type: tileableType,
          tileable_id: tileableId,
          index,
          use_favicon: useFavicon,
        }, idx) => (
          <Draggable
            key={tileId}
            draggableId={tileId}
            index={index || idx}
          >
            {(provided) => (
              <DraggableTile
                style={{ ...provided.draggableProps.style }}
                provided={provided}
                innerref={provided.innerRef}
                key={tileId}
                id={tileId}
                url={url}
                name={tileName}
                icon={icon}
                backgroundcolor={backgroundcolor}
                textcolor={textcolor}
                tileableType={tileableType}
                tileableId={tileableId}
                useFavicon={useFavicon}
              />
            )}
          </Draggable>
        ))}
        {placeholder}
        {!isDefaultFolder && renderAfterTiles()}
      </Row>
    </fieldset>
  );
};

Folder.defaultProps = {
  name: '',
  renderAfterTiles: () => (<></>),
  tiles: [],
  isNameLoading: false,
  placeholder: <></>,
};

Folder.propTypes = {
  name: PropTypes.string,
  tiles: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    backgroundcolor: PropTypes.string,
    textcolor: PropTypes.string,
    tileable_type: PropTypes.string.isRequired,
    index: PropTypes.number,
  })),
  renderAfterTiles: PropTypes.func,
  onUpdateName: PropTypes.func.isRequired,
  isNameLoading: PropTypes.bool,
  innerref: PropTypes.func.isRequired,
  placeholder: PropTypes.element,
};

export default Folder;
