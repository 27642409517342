import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Alert, Button, Col, Form, Row } from 'react-bootstrap';
import { translate } from '@apex/react-toolkit/lib';
import styles from 'App/AuthRoutes/AuthRoutes.module.css';
import { Formik } from 'formik';
import * as yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useGetNewEmailVerificationMutation } from 'api/authSlice';
import { Error } from '@apex/react-toolkit/components';
import NewVerificationSuccess from 'App/AuthRoutes/VerificationNotFound/NewVerificationSuccess';

const VerificationNotFound = () => {
  const [getNewEmailVerification, { data, isLoading, isSuccess, error }] = useGetNewEmailVerificationMutation();
  const [email, setEmail] = useState<string>('');

  const schema = yup.object({
    email: yup.string().email().required('Email Address is required'),
  });

  if (data) return (<NewVerificationSuccess email={email} />);
  return (
    <>
      {
        isSuccess && (
          <Alert variant="success">
            {translate('passwordResetRequested')}
          </Alert>
        )
      }
      {error && <Error error={error} />}
      <Alert variant="danger">
        <strong>{translate('verificationFailed')}</strong>
        <br />
        {translate('verificationNotFound')}
      </Alert>
      <Formik
        validationSchema={schema}
        initialValues={{ email: '' }}
        onSubmit={(formInput) => {
          setEmail(formInput.email);
          getNewEmailVerification(formInput);
        }}
      >
        {({
          handleSubmit,
          handleChange,
          touched,
          values,
          errors,
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Control
                required
                name="email"
                type="email"
                placeholder="Enter email"
                size="lg"
                value={values.email}
                onChange={handleChange}
                disabled={isLoading}
                isValid={touched.email && !errors.email}
              />
            </Form.Group>
            <Button
              variant="danger"
              type="submit"
              size="lg"
              disabled={isLoading}
              className="text-nowrap w-100"
            >
              {isLoading && <FontAwesomeIcon icon="spinner" pulse />} {translate('sendNewVerificationEmail')}
            </Button>
          </Form>
        )}
      </Formik>
      <Row className="my-3">
        <Col className="mx-auto">
          <hr className="border border-gray" />
        </Col>
      </Row>
      <div className="text-center">
        <Link className={styles.authRouteLink} to="/login">{translate('returnToLogin')}</Link>
      </div>
    </>
  );
};

export default VerificationNotFound;
