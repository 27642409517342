import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router, Link, Route, Routes } from 'react-router-dom';
import { Container, Nav } from 'react-bootstrap';
import { ErrorBoundary, PlatformToolbar } from '@apex/react-toolkit/components';
import { translate } from '@apex/react-toolkit/lib';
import { clearToken, clearUser, setToken, setUser } from 'localState/authSlice';
import AppRoutes from 'App/AppRoutes';
import AuthRoutes from 'App/AuthRoutes';
import ApexSettings from 'common/ApexSettings';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Support from 'App/Support';

const App = () => {
  const dispatch = useDispatch();
  // TODO: APEX-686: Convert this `any` type to a custom Redux `RootState` that we define.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const token = useSelector((state: any) => state.auth.token);

  return (
    <Router>
      <Routes>
        <Route path="support" element={<Support />}/>
        <Route
          path="/*"
          element={
            <>
              {token
                ? (
                  <Container fluid className="p-0">
                    <PlatformToolbar
                      icon={<img src='/android-chrome-384x384.png' style={{ height: '20px' }} alt='Apex Icon' className='mb-1' />}
                      navigationItems={[
                        <Nav.Link as={Link} to="/">
                          <FontAwesomeIcon className="me-2" icon="house" />
                          {translate('dashboard')}
                        </Nav.Link>,
                        <Nav.Link as={Link} to="profile">
                          <FontAwesomeIcon className="me-2" icon="id-badge" />
                          {translate('profile')}
                        </Nav.Link>,
                      ]}
                      statusIcons={[]}
                      settingsComponent={<ApexSettings />}
                      // TODO: Define user type
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      onFreshUser={(freshUser: any) => {
                        dispatch(setUser(freshUser));
                      }}
                      // TODO: Define user type
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      onFreshToken={(freshToken: any) => setToken(freshToken)}
                      onLogOut={() => {
                        dispatch(clearToken());
                        dispatch(clearUser());
                      }}
                    />
                    <ErrorBoundary>
                      <AppRoutes />
                    </ErrorBoundary>
                  </Container>
                )
                : <AuthRoutes />}
            </>
          }
        />
      </Routes>
    </Router>
  );
};

export default App;
