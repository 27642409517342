import React from 'react';
import { Alert } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { translate } from '@apex/react-toolkit/lib';
import constants from 'common/constants';

const VerificationReset = () => {
  const navigate = useNavigate();

  setTimeout(() => navigate('/login'), constants.redirectTimeoutDuration);

  return (
    <>
      <Alert variant="success">
        {translate('emailVerifiedSuccessfully')} {translate('redirectingToLogin')} <FontAwesomeIcon icon="spinner" pulse />
      </Alert>
    </>
  );
};

export default VerificationReset;
