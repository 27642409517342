import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from 'yup';
import { translate } from '@apex/react-toolkit/lib';

const ChangePasswordForm = React.forwardRef(({ initialValues, disabled, apiErrors }, ref) => {
  const schema = yup.object({
    current_password: yup.string().required(translate('currentPasswordRequired')),
    new_password: yup.string()
      .required(translate('newPasswordRequired'))
      .min(10)
      .matches(/[a-z]/, `${translate('password')} ${translate('mustContainOneLowerCase')}`)
      .matches(/[A-Z]/, `${translate('password')} ${translate('mustContainOneUpperCase')}`)
      .matches(/[0-9]/, `${translate('password')} ${translate('mustContainOneNumber')}`)
      .matches(/[@$!%*#?&]/, `${translate('password')} ${translate('mustContainOneSpecialChar')}`),
    password_confirmation: yup.string().oneOf([yup.ref('new_password'), null], translate('newAndConfirmPasswordMustMatch')),
  });

  return (
    <Formik
      innerRef={ref}
      validationSchema={schema}
      initialValues={initialValues}
      enableReinitialize
    >
      {({
        handleSubmit,
        handleChange,
        touched,
        values,
        errors,
      }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Form.Group className="mb-3" controlId="current_password">
            <Form.Label>Current Password</Form.Label>
            <Form.Control
              required
              name="current_password"
              type="password"
              placeholder={translate('currentPassword')}
              value={values.current_password}
              onChange={handleChange}
              disabled={disabled}
              isValid={touched.current_password && !errors.current_password}
            />
            <Form.Control.Feedback type="invalid" className="d-block">
              {errors.current_password || (apiErrors?.current_password && apiErrors.current_password.join(','))}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3" controlId="new_password">
            <Form.Label>New Password</Form.Label>
            <Form.Control
              required
              name="new_password"
              type="password"
              placeholder={translate('newPassword')}
              value={values.new_password}
              onChange={handleChange}
              disabled={disabled}
              isValid={touched.new_password && !errors.new_password}
            />
            <Form.Control.Feedback type="invalid" className="d-block">
              {errors.new_password || (apiErrors?.new_password && apiErrors.new_password.join(','))}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3" controlId="password_confirmation">
            <Form.Label>Confirm Password</Form.Label>
            <Form.Control
              required
              name="password_confirmation"
              type="password"
              placeholder={translate('confirmPassword')}
              value={values.password_confirmation}
              onChange={handleChange}
              disabled={disabled}
              isValid={touched.password_confirmation && !errors.password_confirmation}
            />
            <Form.Control.Feedback type="invalid" className="d-block">
              {errors.password_confirmation || (apiErrors?.password_confirmation && apiErrors.password_confirmation.join(','))}
            </Form.Control.Feedback>
          </Form.Group>
        </Form>
      )}
    </Formik>
  );
});

ChangePasswordForm.defaultProps = {
  apiErrors: null,
};

ChangePasswordForm.propTypes = {
  initialValues: PropTypes.shape({
    current_password: PropTypes.string,
    new_password: PropTypes.string,
    password_confirmation: PropTypes.string,
  }).isRequired,
  apiErrors: PropTypes.shape({
    current_password: PropTypes.arrayOf(PropTypes.string),
    new_password: PropTypes.arrayOf(PropTypes.string),
    password_confirmation: PropTypes.arrayOf(PropTypes.string),
  }),
  disabled: PropTypes.bool.isRequired,
};

export default ChangePasswordForm;
