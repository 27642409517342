import { createSlice } from '@reduxjs/toolkit';
import { TOKEN_PATH, LOCALE_PATH } from '@apex/react-toolkit/lib';
import cookie from 'helpers/cookie';
import config from '../config';

const slice = createSlice({
  name: 'auth',
  initialState: {
    user: null,
    token: cookie.get(TOKEN_PATH),
  },
  reducers: {
    setToken: (state, { payload: token }) => {
      state.token = token;
    },
    clearToken: (state) => {
      state.token = null;
    },
    setUser: (state, { payload: user }) => {
      const locale = user?.locale || config.DEFAULT_LOCALE;
      cookie.set(LOCALE_PATH, locale);
      state.user = user;
    },
    clearUser: (state) => {
      state.user = null;
    },
  },
});

export const { setToken, clearToken, setUser, clearUser } = slice.actions;
export const selectCurrentUser = (state) => state.auth.user;
export default slice.reducer;
