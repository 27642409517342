import React from 'react';
import { Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { Tile } from '@apex/react-toolkit/components';
import styles from 'App/AppRoutes/Home/AddDeleteTileTile/AddDeleteTileTile.module.css';

const AddDeleteTileTile = ({ handleClick, isDragging, innerref }) => {
  return (
    <Col sm="12" md="4" lg="2" ref={innerref} className="position-sticky">
      {
        isDragging
          ? (
            <Tile
              className={`${styles.addDeleteTile} mb-4`}
              bodyClassName={styles.tileBody}
              title="Delete Tile"
              icon="trash"
              variant="danger"
            />
          ) : (
            <Tile
              className={`${styles.addDeleteTile} mb-4 cursor-pointer`}
              bodyClassName={styles.tileBody}
              title="Create External Link"
              icon="plus"
              variant="success"
              onClick={handleClick}
            />
          )
      }
    </Col>
  );
};

AddDeleteTileTile.propTypes = {
  handleClick: PropTypes.func.isRequired,
  isDragging: PropTypes.bool.isRequired,
  innerref: PropTypes.func.isRequired,
};

export default AddDeleteTileTile;
