import { translate } from '@apex/react-toolkit/lib';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSubmitVerificationMutation } from 'api/authSlice';
import { Formik } from 'formik';
import { Button, Form } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';

const VerificationSubmit = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const [submitVerification, { data, isLoading, isSuccess, error }] = useSubmitVerificationMutation();

  if (data && isSuccess) {
    if (data.pw_reset_required) {
      navigate(`/reset-password/${data.reset_token}?email=${data.email}`);
    } else {
      navigate('/verify/email/success');
    }
  }

  // for now we want to direct all failures to the not found page
  // this will allow the user to request a new email verification link
  // for now, all auto-regeneration in the backend is turned off
  if (error) {
    navigate('/verify/email/not-found');
  }

  return (
    <>
      <Formik
        className="mt-3"
        initialValues={{}}
        onSubmit={async () => {
          await submitVerification({ token });
        }}
      >
        {({ handleSubmit }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Button
              variant="danger"
              type="submit"
              size="lg"
              className="text-nowrap w-100"
            >
              {isLoading && <FontAwesomeIcon icon="spinner" pulse className="me-1"/>} {translate('verifyMyEmail')}
            </Button>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default VerificationSubmit;
